import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Signal,
  computed,
  effect,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs';
import { LoadSearchResults, getGroupedSearchResults, getSearchQuery, getSearchResultsLoading } from '../../store';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CdkListboxModule } from '@angular/cdk/listbox';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule, NgClass } from '@angular/common';
import { MatChipListboxChange, MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SearchResult } from '../../models';
import { searchResultComponents } from '..';
import { IconComponent } from '@teamfoster/sdk/icon';
import { SwitchTenant } from 'src/app/auth/store';
import { CmsTenant } from 'src/app/auth/models';
import { SearchFilter } from '../../models/search-filter.model';
import { Go } from 'src/app/store';
import { FromDictionaryPipe } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuItem } from 'src/app/menu-items/models';

@Component({
    selector: 'app-command-palette',
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatIcon,
        MatListModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        CdkListboxModule,
        MatChipsModule,
        MatProgressBarModule,
        NgClass,
        CommonModule,
        IconComponent,
        FromDictionaryPipe,
        ...searchResultComponents,
    ],
    templateUrl: './command-palette.component.html',
    styleUrl: './command-palette.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommandPaletteComponent {
  private store = inject(Store);
  private dlg = inject(DialogRef);
  private dlgData = inject(DIALOG_DATA);

  results$ = this.store.selectSignal(getGroupedSearchResults);
  query$ = this.store.selectSignal(getSearchQuery);
  loading$ = this.store.selectSignal(getSearchResultsLoading);
  q$ = signal<string>('');
  // to do: get from store and tenant settings?
  filters$ = signal<SearchFilter[]>([
    { label: "Pagina's", value: 'page' },
    { label: 'Nieuws', value: 'news' },
    { label: 'Instellingen', value: 'settings' },
    { label: 'Media', value: 'Media' },
  ]);
  selectedItem = signal<any>(null);
  selectedFilter = signal<string>('');

  tabItems = signal<string[]>([]);

  queryControl = new FormControl<string>(this.query$());
  queryInput = viewChild<ElementRef<HTMLInputElement>>('queryInput');
  queryValue = toSignal(this.queryControl.valueChanges.pipe(distinctUntilChanged()));

  combinedResults: Signal<{ type: string; results: SearchResult[] }[]> = computed(() => {
    const results = this.results$();
    const tenants: CmsTenant[] = this.dlgData.tenants;
    const menu: MenuItem[] = this.menuLeaves();
    return [
      {
        type: 'Menu',
        results: menu.map(
          item =>
            ({
              id: item.guid,
              icon: item.icon,
              type: 'Menu',
              title: item.title,
              routerLink: item.routerLink,
              queryParams: item.queryParams,
              breadcrumbs: item.parents?.map(a => ({ id: a.guid, icon: a.icon, name: a.title })),
            }) as SearchResult
        ),
      },
      // { type: 'Tenant', results: tenants.map(item => ({ id: item.id, type: 'Tenant', title: item.name }) as SearchResult) },
      ...results,
    ];
  });

  filteredResults = computed(() => {
    const results = this.combinedResults();
    const filter = this.selectedFilter();

    return results.filter(result => result.type.indexOf(filter) > -1);
  });

  resultCount = computed(() => {
    return this.filteredResults().reduce((acc, result) => acc + result.results.length, 0);
  });

  menuLeaves = computed(() => {
    const menu = this.dlgData.menu;
    const q = this.queryValue();
    return this.findLeaves(menu).filter(
      a =>
        a.title!.toLowerCase().indexOf(q?.toLowerCase() || '') > -1 ||
        a.parents?.some(b => b.title!.toLowerCase().indexOf(q?.toLowerCase() || '') > -1)
    );
  });

  valueEffect = effect(
    () => {
      if (this.queryValue()) {
        this.search(this.queryValue()!);
      } else {
        this.q$.set(''); // clear search results
      }
    },
    { allowSignalWrites: true }
  );

  @HostListener('keydown.escape', ['$event'])
  close() {
    this.dlg.close();
  }

  constructor() {
    setTimeout(() => {
      this.queryInput()?.nativeElement.select();
    });
  }

  activate(item: MatAutocompleteSelectedEvent) {
    var selectedResult = item.option.value as SearchResult;
    switch (selectedResult.type) {
      case 'Tenant':
        this.store.dispatch(SwitchTenant({ tenantId: `${selectedResult.id}` }));
        this.dlg.close();
        break;
      case 'Menu':
        if (selectedResult.routerLink) {
          this.store.dispatch(Go({ path: selectedResult.routerLink, queryParams: selectedResult.queryParams }));
        }
        if (selectedResult.breadcrumbs?.length) {
          this.dlg.close({ menuId: `${selectedResult.breadcrumbs[0].id}` });
        } else {
          this.dlg.close({ menuId: `${selectedResult.id}` });
        }
        break;
    }
  }

  filterOn(event: MatChipListboxChange) {
    this.selectedFilter.set(event.value);
  }

  search(query: string) {
    if (!query) {
      this.q$.set('');
      return;
    }

    this.q$.set(query);
    this.store.dispatch(LoadSearchResults({ query }));
  }

  tab() {
    this.tabItems.set([...this.tabItems(), this.queryValue()!]);
    this.queryControl.setValue('');
  }
  backspace() {
    if (this.queryControl.value === '') {
      this.tabItems.set([...this.tabItems().slice(0, -1)]);
    }
  }

  findLeaves(menu: MenuItem[], parents?: MenuItem[]): MenuItem[] {
    return menu.reduce((acc, item) => {
      if (item.menuItems?.length) {
        return [...acc, ...this.findLeaves(item.menuItems as MenuItem[], [...(parents || []), item])];
      }

      let newItem = { ...item };
      if (parent) {
        newItem.parents = [...(item.parents || []), ...(parents || [])];
      }

      return [...acc, newItem];
    }, [] as MenuItem[]);
  }
}
