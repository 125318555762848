import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle } from '@angular/router';

export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
  //public override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
  //  return future.routeConfig === curr.routeConfig || future.data['reuseComponent'];
  //}
  //public override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
  //  return false;
  //}
  public override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const sameRoute = future.url.join('/') === curr.url.join('/');
    const reuse = future?.data['reuseComponent'] || future.firstChild?.data['reuseComponent'] || false;
    //console.log('shouldReuseRoute', reuse);
    return sameRoute && reuse;
  }
}
